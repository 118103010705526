<template>
  <div>
    <div v-for="(item,index) in localCustomFields" :key="item.key" class="custom-field" >
      <el-row>
        <el-col :span="6">
          <el-input
            v-model="item.fields"
            :disabled="item.disabled"
            placeholder="Please Input"
            clearable:style="{width: '50%'}"
          ></el-input>
        </el-col>
        <el-col :span="16">
          <el-input
            v-model="item.value"
            :disabled="item.disabled"
            placeholder="Please Input"
            clearable:style="{width: '50%'}"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            circle
            :disabled="item.disabled"
            @click="addClick()"
          ></el-button>
          <el-button
            type="danger"
            :disabled="item.disabled"
            icon="el-icon-delete"
            circle
            @click="removeClick(index)"
          ></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customFields: {
      type: Array,
      required: true,
    },
    item:Array
  },
  data() {
    return {
      localCustomFields: JSON.parse(JSON.stringify(this.customFields)), // 复制 props 到 data
    };
  },
  create() {
    
  },
  mounted() {
    this.localCustomFields = [];
    this.item.forEach((item) => {
      const num = this.localCustomFields.length + 2;
      const key = "base_info_custom_field_" + num;
      // console.info("item1111:", item);
      let val = item.value;
      this.localCustomFields.push({
        key: key,
        disabled: item.disabled,
        fields: item.fields,
        value: val,
      });
    });
  },
  methods: {
    addClick() {
      // console.info('localCustomFields:',this.localCustomFields)
      const num = this.localCustomFields.length + 2;
      const key = "base_info_custom_field_" + num;
      this.localCustomFields.push({
        key: key,
        disabled: this.item.disabled,
        fields: "",
        value: "",
      });
    },
    removeClick(index) {
      if (this.localCustomFields.length > 1) {
        this.localCustomFields.splice(index, 1);
      }
    },
  },
};
</script>

<style>
.custom-field {
  margin-top: 10px;
}
</style>