<template>
  <div>
    <el-popover
      placement="top-start"
      width="300"
      trigger="hover"
      class="rev-popover-container"
    >
      <!-- 省略内容 -->
      <div class="rev-popover">
        <el-row class="rev-row">
          <el-col :span="8"><h4>Rev:</h4></el-col>
          <el-col :span="16">{{versionInfo.revNumber}}</el-col>
        </el-row>
        <el-row class="rev-row">
          <el-col :span="8"><h4>Created By:</h4></el-col>
          <el-col :span="8">{{versionInfo.createName}} </el-col>
          <el-col :span="8">{{versionInfo.createTime}} </el-col>
        </el-row>
        <el-row class="rev-row">
          <el-col :span="8"><h4>Updated By:</h4></el-col>
          <el-col :span="8">{{versionInfo.updateName}}</el-col>
          <el-col :span="8">{{versionInfo.updateTime}}</el-col>
        </el-row>
        <el-row class="rev-row" v-if="pageStatus=='Detail'">
          <el-col :span="8"><h4>Verified By:</h4></el-col>
          <el-col :span="8">{{versionInfo.verifiedName}} </el-col>
          <el-col :span="8">{{versionInfo.verifiedTime}}</el-col>
        </el-row>
      </div>
      <el-button slot="reference" class="revInfo">Rev Info</el-button>
    </el-popover>
  </div>
</template>
<script>

export default {
  data() {
    return {
      revision:{}
    }
  },
  props: {
    pageStatus:String,
    versionInfo: Object
  },
  created() {
  },
  methods: {
    
  }
}
</script>
