import * as defaultData from "../../data/DefaultData";

export const PCG = {
    base_info: {
        product_type: {
            type: "select",
            label: "Product Type",
            tips_value: "",
            value: "",
            disabled: false,
            options_value: defaultData.ProductTypeData,
            eventHandle: "productTypeChange"
        },
        product_name: {
            type: "input",
            label: "Product Name",
            tips_value: "",
            value: "",
            disabled: false
        },
        item_code: {
            type: "input",
            label: "Item Code",
            tips_value: "",
            value: "",
            disabled: false
        },
        version_number: {
            type: "input",
            label: "Version Number",
            tips_value: "",
            value: "",
            disabled: false
        },
        product_description:
        {
            type: "textarea",
            label: "Product Description",
            tips_value: "Spray dried, fine powdered botanical extract of ingredient that has been extracted in a controlled temperature, full spectrum extraction process. ",
            value: "", 
            disabled: false
        },
        physical_properties: {
            type: "input",
            label: "Physical Properties",
            tips_value: "",
            value: "",
            disabled: false
        },
        confidential: {
            type: "checkbox",
            label: "Confidential",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        custom_formula: {
            type: "checkbox",
            label: "Custom Formula",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        document_notes: {
            type: "input",
            label: "Document Notes",
            tips_value: "",
            value: "",
            disabled: false
        }
    },
    general_specification: {
        ingredients: {
            type: "tag",
            label: "Ingredients",
            tips_value: [],
            value: "",
            disabled: false
        },
        botanical_name: {
            type: "tag",
            label: "Botanical Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        common_other_name: {
            type: "tag",
            label: "Common Other Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        inci_name: {
            type: "tag",
            label: "Inci Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        part_used: {
            type: "tag",
            label: "Part Used",
            tips_value: [],
            value: "",
            disabled: false
        },
        percentage: {
            type: "tag",
            label: "Percentage",
            tips_value: [],
            value: "",
            disabled: false
        },
        country_of_origin: {
            type: "input",
            label: "Country Of Origin",
            tips_value: "",
            value: "",
            disabled: false
        },
        extraction_ratio: {
            type: "input",
            label: "Extraction Ratio",
            tips_value: "",
            value: "",
            disabled: false
        },
        important_constitue_in_plant: {
            type: "input",
            label: "Important Constitue In Plant",
            tips_value: "",
            value: "",
            disabled: false
        },
        appearance: {
            type: "input",
            label: "Appearance",
            tips_value: "Powder",
            value: "",
            disabled: false
        },
        solubility: {
            type: "input",
            label: "Solubility",
            tips_value: "Water soluble/dispersible",
            value: "",
            disabled: false
        },
        homogeneity: {
            type: "input",
            label: "Homogeneity",
            tips_value: "Completely homogenous",
            value: "",
            disabled: false
        },
        extraction_process: {
            type: "input",
            label: "Extraction Process",
            tips_value: "Controlled-temperature water extraction process, spray dried.",
            value: "",
            disabled: false
        },
        processing_aid: {
            type: "input",
            label: "Processing Aid",
            tips_value: "",
            value: "",
            disabled: false
        },
        solvent_for_extraction: {
            type: "input",
            label: "Solvent For Extraction",
            tips_value: "Water",
            value: "",
            disabled: false
        },
        carrier: {
            type: "input",
            label: "Carrier",
            tips_value: "None",
            value: "",
            disabled: false
        },
        pesticides: {
            type: "input",
            label: "Pesticides",
            tips_value: "",
            value: "",
            disabled: false
        },
        certification: {
            type: "input",
            label: "Certification",
            tips_value: "Kosher certified, HALAL certified",
            value: "",
            disabled: false
        },
        product_grade: {
            type: "input",
            label: "Product Grade",
            tips_value: "Personal Care Grade",
            value: "",
            disabled: false
        }
    },
    analysis: {
        standard1: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "DracoUV-",
                value: "",
                disabled: false
            }
        },
        standard2: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "DracoHPLC-",
                value: "",
                disabled: false
            }
        },
        standard3: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "",
                value: "",
                disabled: false
            }
        },
        loss_on_drying: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<6%",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Draco-1.1.1.0",
                value: "",
                disabled: false
            }
        },
        bulk_density: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "0.25-0.75 g/mL",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP <616>",
                value: "",
                disabled: false
            }
        },
        mesh_size: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: ">95% pass 80 Mesh",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Metric Sieve",
                value: "",
                disabled: false
            }
        },
        identification: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Conforms",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "HPTLC",
                value: "",
                disabled: false
            }
        },
        total_meavy_metals: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<10 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<233>",
                value: "",
                disabled: false
            }
        },
        cadmium: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        lead: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        mercury: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        arsenic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        color: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Visual",
                value: "",
                disabled: false
            }
        },
        odor: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        },
        taste: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        }
    },
    microbiological_tests: {
        total_plate_count: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<100 cfu/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        yeast_and_mold: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<100 cfu/g",
                value: "",
                disabled: false
            },
            testMethod: {
                type: "input",
                label: "Testmethod",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        salmonella: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Negative",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        staph_aureus: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Negative",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        total_coliforms: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<10 MPN/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        ecoli: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Negative",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        }
    },
    other_notes: {
        shelflife_number: {
            type: "input",
            label: "Shelflife Number",
            tips_value: null,
            value: "",
            disabled: false
        },
        shelf_life: {
            type: "input",
            label: "Shelf Life",
            tips_value: ". Product is sensitive to heat and moisture.",
            value: "",
            disabled: false
        },
        storage: {
            type: "input",
            label: "Storage",
            tips_value: "Preserve in airtight containers in a cool area, dry area.",
            value: "",
            disabled: false
        },
        package_info: {
            type: "input",
            label: "Package Info",
            tips_value: "",
            value: "",
            disabled: false
        }
    }
}