<template>
  <div style="position: relative;">
    <el-input
      type="textarea"
      v-model="localValue"
      :disabled="item.disabled"
      :placeholder="'Please Input ' + item.label"
      clearable
      :autosize="{ minRows: 2, maxRows: 8 }"
      :style="textareaDefaultStyle"
    ></el-input>
    <template v-if="item.tips_value.length > 0">
      <el-popover
        placement="top-start"
        :content="item.tips_value"
        trigger="hover"
        style="position: absolute; right: 10px; top: 10px"
      >
        <el-button
          slot="reference"
          icon="el-icon-edit"
          size="small"
          type="primary"
          @click="defaultValueHandle(item)"
        >Default</el-button>
      </el-popover>
    </template>
  </div>
</template>

<script>
import { EventBus } from "../config/eventBus";
export default {
  props: {
    item: Object,
    value: String,
    controlData: Object
  },
  data() {
    return {
      localValue: this.value, // 将 prop 赋值给 data 属性
    };
  },
  watch: {
    value(newVal) {
      console.info('newVal:',newVal)
      this.localValue = newVal; // 当 prop 变化时，同步到 data 属性
    },
    localValue(newVal) {
      this.$emit("input", newVal); // 当 data 属性变化时，同步到父组件
    },
  },
  computed: {
    textareaDefaultStyle() {
      return this.item.tips_value
        ? "width: 100%; box-sizing: border-box; padding-right: 120px;"
        : "";
    },
  },
  mounted() {
    // this.tipsSetup();
  },
  created(){ 
    EventBus.$on("sendTagDataEvent", this.tagDataEvent);
  },
  methods:{
    defaultValueHandle(item){
      this.localValue = item.tips_value;
    },
    tagDataEvent(data){
      if(data.name=='Botanical Name'){
        let typeList = ['PCG-CSFMU-B',"PCG-CSFMU-S","PCG","OEXT","CSFMU-B","CSFMU-S","F&V","FMU-D","HEXT"];

        if(typeList.includes(this.controlData.list.product_type.value)){
          let defaultValue = "ingredient";
          //botanical_name.join('_')
          if(data.value){
            defaultValue = data.value.join('_');
          }

          let botanical_str = 'Spray dried, fine powdered botanical extract of ${botanical_str} that has been extracted in a controlled temperature, full spectrum extraction process.';
          let replaceValue = botanical_str.replace("${botanical_str}",defaultValue);
          this.item.tips_value = replaceValue;
        }
        
      }
    }
  }
};
</script>
