import * as defaultData from "../../data/DefaultData";

export const EHXTO = {
    base_info: {
        product_type: {
            type: "select",
            label: "Product Type",
            tips_value: "",
            value: "",
            disabled: false,
            options_value: defaultData.ProductTypeData,
            eventHandle: "productTypeChange"
        },
        product_name: {
            type: "input",
            label: "Product Name",
            tips_value: "",
            value: "",
            disabled: false
        },
        item_code: {
            type: "input",
            label: "Item Code",
            tips_value: "",
            value: "",
            disabled: false
        },
        product_description: {
            type: "textarea",
            label: "Product Description",
            tips_value: "",
            value: "",
            disabled: false
        },
        version_number: {
            type: "input",
            label: "Version Number",
            tips_value: "",
            value: "",
            disabled: false
        },
        processing: {
            type: "input",
            label: "Processing",
            tips_value: "",
            value: "",
            disabled: false
        },
        physical_properties: {
            type: "input",
            label: "Physical Properties",
            tips_value: "Essential oil",
            value: "",
            disabled: false
        },
        confidential: {
            type: "checkbox",
            label: "Confidential",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        custom_formula: {
            type: "checkbox",
            label: "Custom Formula",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        document_notes: {
            type: "input",
            label: "Document Notes",
            tips_value: "",
            value: "",
            disabled: false
        }
    },
    general_specification: {
        botanical_name: {
            type: "tag",
            label: "Botanical Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        common_other_name: {
            type: "tag",
            label: "Common Other Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        inci_name: {
            type: "tag",
            label: "Inci Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        part_used: {
            type: "tag",
            label: "Part Used",
            tips_value: [],
            value: "",
            disabled: false
        },
        percentage: {
            type: "tag",
            label: "Percentage",
            tips_value: [],
            value: "",
            disabled: false
        },
        country_of_origin: {
            type: "input",
            label: "Country Of Origin",
            tips_value: "China",
            value: "",
            disabled: false
        },
        important_constitue_in_plant: {
            type: "input",
            label: "Important Constitue In Plant",
            tips_value: "",
            value: "",
            disabled: false
        },
        appearance: {
            type: "input",
            label: "Appearance",
            tips_value: "Oil",
            value: "",
            disabled: false
        },
        solubility: {
            type: "input",
            label: "Solubility",
            tips_value: "Oil soluble",
            value: "",
            disabled: false
        },
        homogeneity: {
            type: "input",
            label: "Homogeneity",
            tips_value: "Completely homogenous",
            value: "",
            disabled: false
        },
        solvent_for_extraction: {
            type: "input",
            label: "Solvent For Extraction",
            tips_value: "",
            value: "",
            disabled: false
        },
        carrier: {
            type: "input",
            label: "Carrier",
            tips_value: "",
            value: "",
            disabled: false
        },
        pesticides: {
            type: "input",
            label: "Pesticides",
            tips_value: "Conforms to USP<565>",
            value: "",
            disabled: false
        },
        certification: {
            type: "input",
            label: "Certification",
            tips_value: "",
            value: "",
            disabled: false
        }
    },
    analysis: {
        specifica_gravity: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "1.0200~1.0430 g/mL",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<841>",
                value: "",
                disabled: false
            }
        },
        optical_rotation: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "-3\u00b0~+1\u00b0",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<781>",
                value: "",
                disabled: false
            }
        },
        refractive_index: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "1.5750~1.6010",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<831>",
                value: "",
                disabled: false
            }
        },
        total_meavy_metals: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<20 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<233>",
                value: "",
                disabled: false
            }
        },
        cadmium: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<0.5 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 986.15",
                value: "",
                disabled: false
            }
        },
        lead: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<0.5 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ca 18c-91",
                value: "",
                disabled: false
            }
        },
        mercury: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<0.5 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 971.21",
                value: "",
                disabled: false
            }
        },
        arsenic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<0.5 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 986.15",
                value: "",
                disabled: false
            }
        },
        moisture: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "%",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: " AOCS Ca 2d-25 ",
                value: "",
                disabled: false
            }
        },
        color: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Visual",
                value: "",
                disabled: false
            }
        },
        odor: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Characteristic",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        },
        taste: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Characteristic",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        }
    },
    microbiological_tests: {
        total_plate_count: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        yeast_and_mold: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        salmonella: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        staph_aureus: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        total_coliforms: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        ecoli: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        }
    },
    other_notes: {
        shelflife_number: {
            type: "input",
            label: "Shelflife Number",
            tips_value: 24,
            value: "",
            disabled: false
        },
        shelf_life: {
            type: "input",
            label: "Shelf Life",
            tips_value: ".",
            value: "",
            disabled: false
        },
        storage: {
            type: "input",
            label: "Storage",
            tips_value: "Preserve in airtight containers in a cool area, dry area.",
            value: "",
            disabled: false
        },
        package_info: {
            type: "input",
            label: "Package Info",
            tips_value: "",
            value: "",
            disabled: false
        }
    }
}