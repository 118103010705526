//属性标题转换
export function formatTitle(str) {
  if(!str){
    return "";
  }
  return str
        // 将分隔符替换为空格（包括下划线和其他可能的分隔符，如连字符）
        .replace(/[_-]+/g, ' ')
        // 将每个单词的首字母大写
        .replace(/\b\w/g, char => char.toUpperCase());
}

// 读取数据格式转换
export function transformMixedData(originalData, templateData) {
  // 定义一个辅助函数，递归地处理原始数据和模板数据中的嵌套对象。
  function mapMixedValues(original, template) {
      // 遍历模板数据中的所有键。
      for (const key in template) {
          // 确保当前键是模板数据自身的属性。
          if (Object.prototype.hasOwnProperty.call(template, key)) {
              // 如果当前键对应的值是对象并且不为 null，则进行下一步处理。
              if (typeof template[key] === 'object' && template[key] !== null) {
                  // 如果模板数据中的对象有 'value' 属性，则直接赋值或设置为空字符串。
                  // console.info('template[key]:',template[key])
                  if (Object.prototype.hasOwnProperty.call(template[key], 'value')) {
                      if(Object.prototype.toString.call(original[key]) === '[object Object]'){
                        // console.info('original[key]:',original[key])
                        template[key].fields = original[key].fields;
                        template[key].value = original[key].value;
                        // console.info('template[key]:',template[key])
                      }else{
                        template[key].value = original[key] || '';
                      }
                      // console.info('tr---------')
                      // console.info('key:',key);
                      // console.info('original',original)
                      // console.info(' original[key]:', original[key])
                  } else {
                      // 如果没有 'value' 属性，则递归调用 mapMixedValues 处理嵌套的对象。
                      mapMixedValues(original[key] || {}, template[key]);
                  }
              }
          }
      }
  }

  // 使用 JSON 的深拷贝方法创建模板数据的一个副本，以避免修改原始模板数据。
  const transformedData = JSON.parse(JSON.stringify(templateData));
  // console.info('originalData:',originalData)
  // console.info("transformedData",transformedData);
  // 调用 mapMixedValues 函数处理原始数据和模板数据的副本。
  mapMixedValues(originalData, transformedData);
  // 返回处理后的模板数据副本。
  return transformedData;
}

// 存储数据结构转换
function transformListData(list) {
  const transformedData = {};
  for (const key in list) {
      if (Object.prototype.hasOwnProperty.call(list, key)) {
        const type = list[key].type;
        let value = list[key].value !== undefined ? list[key].value : "";
        if(type=='checkbox'){
          value = list[key].checked;
          if(value==undefined){
            value = false;
          }
        }
        transformedData[key] = value;
      }
  }
  return transformedData;
}

function transformTestData(leftList, rightList) {
  const transformedData = {};
  leftList.forEach((item, index) => {
      const label = item.label.toLowerCase();
      const specification = item.value !== undefined ? item.value : "";
      const test_method = rightList[index]?.value !== undefined ? rightList[index].value : "";
      transformedData[label] = {
          specification,
          test_method
      };
  });
  return transformedData;
}

// 存储数据结构转换
export function transformSaveData(data) {
  const result = {};

  for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
          const section = data[key];
          let newKey = key.replace("Data","").replace(/([A-Z])/g, '_$1')  // 在大写字母前添加下划线
          .toLowerCase();
          if(typeof(section)=='undefined'){
              continue;
          }
          if (section.list) {
              // 处理 baseinfo 类型的结构
              result[newKey] = transformListData(section.list);
          } else if (section.leftList && section.rightList) {
              // 处理 microbiologicalTestsData 类型的结构
              result[newKey] = transformTestData(section.leftList, section.rightList);
          }
      }
  }

  return result;
}

