<template>
  <div>
    <el-form
      ref="baseInfoForm"
      size="medium"
      :model="localModel"
      label-width="200px"
    >
      <el-row>
        <el-col
          v-for="column in getColumns"
          :key="column.key"
          :span="column.span"
        >
          <el-form-item
            :label="formatTitle(item.label)"
            v-for="(item, key) in column.list"
            :key="key"
          >
            <component
              :is="getComponentType(getArrayItemType(item))"
              :item="item"
              :controlData="controlData"
              :value="localModel[item.value]"
              @input="updateValue(column.key, getAttr(getArrayItemLabel(item)), $event)"
              :customFields.sync="custom_fields"
            ></component>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import CustomField from "./components/CustomField.vue";
import TagComponents from "./components/TagComponents.vue";
import ElSelect from "./components/ElSelect.vue";
import ElInput from "./components/ElInput.vue";
import ElTextarea from "./components/ElTextarea.vue";
import ElCheckbox from "./components/ElCheckbox.vue";
// import * as TypeData from "./data/allTypeData";
import * as StructConvertUtils from "./config/structConvertUtils";
export default {
  components: {
    CustomField,
    TagComponents,
    ElSelect,
    ElInput,
    ElTextarea,
    ElCheckbox,
  },
  props: {
    controlData: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mappingModel: {},
      custom_fields: [
        {
          key: "base_info_custom_field_1",
          fields: "",
          value: "",
          disable: true,
        },
      ],
    };
  },
  computed: {
    localModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    getColumns() {
      const columns = [];
      if (this.controlData.row === 1) {
        columns.push({ key: "full", span: 24, list: this.controlData.list });
      } else if (this.controlData.row === 2) {
        columns.push({
          key: "left",
          span: 12,
          list: this.controlData.leftList,
        });
        columns.push({
          key: "right",
          span: 12,
          list: this.controlData.rightList,
        });
      }
      return columns;
    },
  },
  created() {},
  methods: {
    getArrayItemType(item) {
      if(item instanceof Array ){
        return item[0].type;
      }
      return item.type;
    },
    getArrayItemLabel(item) {
      if(item instanceof Array ){
        return item[0].label;
      }
      return item.label;
    },
    getArrayItemValue() {},
    formatTitle(title) {
      let resTitle = StructConvertUtils.formatTitle(title);
      return resTitle;
    },
    handleEvent(functionName) {
      this[functionName]();
    },
    getComponentType(type) {
      const types = {
        select: "ElSelect",
        input: "ElInput",
        textarea: "ElTextarea",
        checkbox: "ElCheckbox",
        tag: "TagComponents",
        custom_input: "CustomField",
      };
      return types[type] || "div";
    },
    getAttr(label) {
      return label.toLowerCase().replace(/ /g, "_");
    },
    updateValue(columnKey, label, value) {
      if (!this.localModel[columnKey]) {
        this.$set(this.localModel, columnKey, {});
      }
      this.$set(this.localModel[columnKey], label, value);
    },
  },
};
</script>
