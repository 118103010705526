import * as defaultData from "../../data/DefaultData";

export const SHXTO = {
    base_info: {
        product_type: {
            type: "select",
            label: "Product Type",
            tips_value: "",
            value: "",
            disabled: false,
            options_value: defaultData.ProductTypeData,
            eventHandle: "productTypeChange"
        },
        product_name: {
            type: "input",
            label: "Product Name",
            tips_value: "",
            value: "",
            disabled: false
        },
        customer_product_name: {
            type: "input",
            label: "Customer Product Name",
            tips_value: "",
            value: "",
            disabled: false
        },
        item_code: {
            type: "input",
            label: "Item Code",
            tips_value: "",
            value: "",
            disabled: false
        },
        version_number: {
            type: "input",
            label: "Version Number",
            tips_value: "",
            value: "",
            disabled: false
        },
        processing: {
            type: "input",
            label: "Processing",
            tips_value: "",
            value: "",
            disabled: false
        },
        product_description: {
            type: "textarea",
            label: "Product Description",
            tips_value: "",
            value: "",
            disabled: false
        },
        physical_properties: {
            type: "input",
            label: "Physical Properties",
            tips_value: "",
            value: "",
            disabled: false
        },
        confidential: {
            type: "checkbox",
            label: "Confidential",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        custom_formula: {
            type: "checkbox",
            label: "Custom Formula",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        document_notes: {
            type: "input",
            label: "Document Notes",
            tips_value: "",
            value: "",
            disabled: false
        }
    },
    general_specification: {
        botanical_name: {
            type: "tag",
            label: "Botanical Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        common_other_name: {
            type: "tag",
            label: "Common Other Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        inci_name: {
            type: "tag",
            label: "Inci Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        part_used: {
            type: "tag",
            label: "Part Used",
            tips_value: [],
            value: "",
            disabled: false
        },
        country_of_origin: {
            type: "input",
            label: "Country Of Origin",
            tips_value: "",
            value: "",
            disabled: false
        },
        appearance: {
            type: "input",
            label: "Appearance",
            tips_value: "Viscous liquid",
            value: "",
            disabled: false
        }
    },
    analysis: {
        total_meavy_metals: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<20 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<233>",
                value: "",
                disabled: false
            }
        },
        cadmium: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 986.15",
                value: "",
                disabled: false
            }
        },
        lead: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ca 18c-91",
                value: "",
                disabled: false
            }
        },
        mercury: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 971.21",
                value: "",
                disabled: false
            }
        },
        arsenic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOAC 986.15",
                value: "",
                disabled: false
            }
        },
        pesticides: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Conforms to USP<565>",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<565>",
                value: "",
                disabled: false
            }
        },
        peroxide_value: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "NMT 10.0 meq/kg",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Cd 8b-90",
                value: "",
                disabled: false
            }
        },
        saponification_value: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "mg KOH/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Cd 3-25",
                value: "",
                disabled: false
            }
        },
        unsaponifiable_master: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "%",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ca 6a-40",
                value: "",
                disabled: false
            }
        },
        moisture_volatiles: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "%",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ca 2d-25",
                value: "",
                disabled: false
            }
        },
        acid_value: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "NMT 10 mg KOH/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Cd 3d-63",
                value: "",
                disabled: false
            }
        },
        color: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Visual",
                value: "",
                disabled: false
            }
        },
        odor: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Characteristic",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        },
        taste: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Characteristic",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        }
    },
    fatty_acid: {
        palmitica: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        palmitoleic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        stearic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        oleic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        y_linoleic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        a_linolenic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        others: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        caprylic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        capric: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        lauric: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        myristic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        linoleic_acid: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        myristoleic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        arachidic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        arachidonic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        eicosapentaenoic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        docosahexaenoic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        },
        punicic_acid: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "AOCS Ce 1e-91",
                value: "",
                disabled: false
            }
        }
    },
    microbiological_tests: {
        total_plate_count: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        yeast_and_mold: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        salmonella: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        staph_aureus: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        total_coliforms: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        ecoli: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        }
    },
    other_notes: {
        shelflife_number: {
            type: "input",
            label: "Shelflife Number",
            tips_value: null,
            value: "",
            disabled: false
        },
        shelf_life: {
            type: "input",
            label: "Shelf Life",
            tips_value: " in the original package",
            value: "",
            disabled: false
        },
        storage: {
            type: "input",
            label: "Storage",
            tips_value: "Preserve in airtight containers in a cool area, dry area.",
            value: "",
            disabled: false
        },
        package_info: {
            type: "input",
            label: "Package Info",
            tips_value: "",
            value: "",
            disabled: false
        }
    }
}