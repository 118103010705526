import * as defaultData from "../../data/DefaultData";

export const LiquidEXT_S = {
    base_info: {
        product_type: {
            type: "select",
            label: "Product Type",
            tips_value: "",
            value: "",
            disabled: false,
            options_value: defaultData.ProductTypeData,
            eventHandle: "productTypeChange"
        },
        product_name: {
            type: "input",
            label: "Product Name",
            tips_value: "",
            value: "",
            disabled: false
        },
        item_code: {
            type: "input",
            label: "Item Code",
            tips_value: "",
            value: "",
            disabled: false
        },
        version_number: {
            type: "input",
            label: "Version Number",
            tips_value: "",
            value: "",
            disabled: false
        },
        physical_properties: {
            type: "input",
            label: "Physical Properties",
            tips_value: "",
            value: "",
            disabled: false
        },
        confidential: {
            type: "checkbox",
            label: "Confidential",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        custom_formula: {
            type: "checkbox",
            label: "Custom Formula",
            tips_value: false,
            value: "",
            disabled: false,
            checked: false
        },
        document_notes: {
            type: "input",
            label: "Document Notes",
            tips_value: "",
            value: "",
            disabled: false
        },
        product_description: {
            type: "textarea",
            label: "Product Description",
            tips_value: "Liquid botanical extract solution prepared by mixing full spectrum bioactive extract with solvent. Original extract is derived from a proprietary, closed system, temperature controlled process in which the majority of the active phytocompounds are retained for full bioactivity.",
            value: "",
            disabled: false
        }
    },
    general_specification: {
        botanical_name: {
            type: "tag",
            label: "Botanical Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        common_other_name: {
            type: "tag",
            label: "Common Other Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        inci_name: {
            type: "tag",
            label: "Inci Name",
            tips_value: [],
            value: "",
            disabled: false
        },
        part_used: {
            type: "tag",
            label: "Part Used",
            tips_value: [],
            value: "",
            disabled: false
        },
        percentage: {
            type: "tag",
            label: "Percentage",
            tips_value: [],
            value: "",
            disabled: false
        },
        country_of_origin: {
            type: "input",
            label: "Country Of Origin",
            tips_value: "",
            value: "",
            disabled: false
        },
        major_constituents_in_native_herbs: {
            type: "input",
            label: "Major Constituents In Native Herbs",
            tips_value: "",
            value: "",
            disabled: false
        },
        appearance: {
            type: "input",
            label: "Appearance",
            tips_value: "Liquid",
            value: "",
            disabled: false
        },
        solvent_for_extraction: {
            type: "input",
            label: "Solvent For Extraction",
            tips_value: "",
            value: "",
            disabled: false
        },
        carrier: {
            type: "input",
            label: "Carrier",
            tips_value: "",
            value: "",
            disabled: false
        },
        preservative: {
            type: "input",
            label: "Preservative",
            tips_value: "",
            value: "",
            disabled: false
        }
    },
    analysis: {
        bioactives_level: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "",
                value: "",
                disabled: false
            }
        },
        identification: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Standard plate",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "HPTLC",
                value: "",
                disabled: false
            }
        },
        total_meavy_metals: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<20 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<233>",
                value: "",
                disabled: false
            }
        },
        cadmium: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        lead: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        mercury: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        arsenic: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<1.0 ppm",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<232>",
                value: "",
                disabled: false
            }
        },
        pesticides: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "conforms to USP<565>",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "USP<565>",
                value: "",
                disabled: false
            }
        },
        ph: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "3.5-7.5",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "pH meter",
                value: "",
                disabled: false
            }
        },
        color: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Visual",
                value: "",
                disabled: false
            }
        },
        odor: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        },
        taste: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "Compares to standard",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "Organoleptic",
                value: "",
                disabled: false
            }
        }
    },
    microbiological_tests: {
        total_plate_count: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<100 cfu/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        yeast_and_mold: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "<100 cfu/g",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM",
                value: "",
                disabled: false
            }
        },
        salmonella: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM ",
                value: "",
                disabled: false
            }
        },
        staph_aureus: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM ",
                value: "",
                disabled: false
            }
        },
        total_coliforms: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM ",
                value: "",
                disabled: false
            }
        },
        ecoli: {
            specification: {
                type: "input",
                label: "Specification",
                tips_value: "",
                value: "",
                disabled: false
            },
            test_method: {
                type: "input",
                label: "Test Method",
                tips_value: "FDA-BAM ",
                value: "",
                disabled: false
            }
        }
    },
    other_notes: {
        shelflife_number: {
            type: "input",
            label: "Shelflife Number",
            tips_value: null,
            value: "",
            disabled: false
        },
        shelf_life: {
            type: "input",
            label: "Shelf Life",
            tips_value: ".",
            value: "",
            disabled: false
        },
        storage: {
            type: "input",
            label: "Storage",
            tips_value: "Preserve in airtight containers in a cool area, dry area.",
            value: "",
            disabled: false
        },
        package_info: {
            type: "input",
            label: "Package Info",
            tips_value: "",
            value: "",
            disabled: false
        }
    }
}