export const CSFMU_B_TESTDATA = {
  base_info: {
    product_name: "",
    customer_product_name: null,
    version_number: null,
    item_code: "",
    product_type: "CSFMU-B",
    processing: null,
    product_description: "",
    physical_properties: null,
    confidential: null,
    custom_formula: null,
    document_notes: null,
    custom_field: []
  },
  general_specification: {
    ingredients: [],
    botanical_name: [],
    common_other_name: [],
    inci_name: [],
    part_used: [],
    percentage: [],
    country_of_origin: null,
    extraction_ratio: "",
    major_constituents_in_native_herbs: null,
    important_constitue_in_plant: "",
    appearance: "",
    solubility: "",
    homogeneity: "",
    extraction_process: null,
    processing_aid: null,
    solvent_for_extraction: "",
    carrier: "",
    pesticides: null,
    certification: "",
    preservative: null,
    product_grade: null,
    custom_field: [],
    custom_field_before_regular_data: []
  },
  other_notes: {
    shelflife_number: null,
    shelf_life: null,
    storage: null,
    notes: [],
    package_info: null
  },
  revision: [],
  analysis: {
    taste: {
      specification: "",
      test_method: ""
    },
    total_plate_count: {
      test_method: "",
      specification: ""
    },
    salmonella: {
      test_method: "",
      specification: ""
    },
    odor: {
      specification: "",
      test_method: ""
    },
    bulk_density: {
      specification: ""
    },
    yeast_and_mold: {
      test_method: "",
      specification: ""
    },
    total_heavy_metals: {
      specification: ""
    },
    color: {
      specification: ""
    },
    staph_aureus: {
      test_method: "",
      specification: ""
    },
    losson_drying: {
      specification: ""
    },
    mesh_size: {
      specification: ""
    },
    ecoli: {
      test_method: "",
      specification: ""
    },
    total_coliforms: {
      specification: "",
      test_method: ""
    }
  }
};
