<template>
  <el-input
    v-model="item.value"
    :placeholder="'Please Input ' + formatTitle(item.label)"
    :disabled="item.disabled"
    clearable
    style="width: 100%"
  >
    <template v-if="showControl(item)" #suffix>
      <el-popover
        placement="top-start"
        :content="item.tips_value"
        trigger="hover"
        style="text-align: center"
      >
        <el-button
          slot="reference"
          icon="el-icon-edit"
          size="small"
          type="primary"
          @click="defaultValueHandle(item)"
          >Default</el-button
        >
      </el-popover>
    </template>
  </el-input>
</template>

<script>
import * as StructConvertUtils from "../config/structConvertUtils";
export default {
  props: {
    item: Object,
    controlData: Object
  },
  data() {
    return {};
  },
  methods: {
    showControl(item) {
      return item?.tips_value?.length > 0;
    },
    defaultValueHandle(item) {
      this.item.value = item.tips_value;
        this.$forceUpdate();
    },
    formatTitle(title){
      let resTitle = StructConvertUtils.formatTitle(title);
      return resTitle;
    },
    shelfLifeTipsSetup(){
      if(this.item.label=='Shelf Life'){
        let tips_value = this.controlData.list.shelflife_number.tips_value+" months unopened "+ this.item.tips_value;
        this.item.tips_value = tips_value;
      }
    }
  },
  created() {
    this.shelfLifeTipsSetup();
    // console.info("item.tips_value--:", this.item);
  },
};
</script>
