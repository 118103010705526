<template>
  <el-checkbox
    v-model="item.checked"
    :disabled="item.disabled"
    style="float: left"
  ></el-checkbox>
</template>

<script>
export default {
  props: {
    item: Object,
    value: String,
  },
  computed: {
    localValue: {
      get() {
        return this.checked;
      },
      set(newValue) {
        this.$emit('input', newValue);  // Vue 2
        // this.$emit('update:value', newValue); // Vue 3
      }
    }
  }
};
</script>
