<template>
  <div class="content">
    <el-tabs v-model="tabModel" type="border-card" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"><i class="el-icon-date"></i> Detail Manager</span>
        <SpecTabPane
          :itemList="itemList"
          :formData="formData"
          :revisionData="revisionData"
          :pageStatus="pageStatus"
          v-if="itemList.length > 0"
        ></SpecTabPane>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        v-for="(item, index) in tabList"
        :key="index"
        v-loading="loading"
        element-loading-text="data loading"
        :name="'tab' + (index + 2)"
        class="tab"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> Version {{ item.revNumber }}</span
        >
        <SpecTabPane
          :itemList="itemList"
          :formData="formData"
          :revisionData="revisionData"
          :pageStatus="pageStatus"
          v-if="itemList.length > 0"
        ></SpecTabPane>
      </el-tab-pane>
    </el-tabs>

    <RevPopover
      :pageStatus="pageStatus"
      :versionInfo="versionInfo"
    ></RevPopover>
  </div>
</template>

<script>
import { EventBus } from "./config/eventBus";
import * as TypeConfig from "./config/allTypeConfig";
import * as StructConvertUtils from "./config/structConvertUtils";

import SpecTabPane from "./SpecTabPane.vue";
import RevPopover from "./components/RevPopover.vue";

export default {
  components: { SpecTabPane, RevPopover },
  data() {
    return {
      loading: true,
      tabModel: "tab1",
      selectType: "", // 当前选中的产品类型
      pageStatus: "Add", // 页面状态 Add、Edit、Detail
      itemList: [], //根据类型获取模板拥有的模块
      baseInfoData: {},
      generalSpecificationData: {},
      analysisData: {},
      microbiologicalTestsData: {},
      otherNotesData: {},
      revisionData: [],
      defaultData: {}, // 用于记录初始对象
      formData: {}, // 用于存储所有字段的值
      loadSpecData: {}, // api 加载的数据
      isPrintVerify: false, // 是否打印 verify 信息
      tabList: [],
      versionInfo: {}, //版本信息
    };
  },
  provide() {
    return {
      getControlDataMethod: this.getControlData,
      getFormDataMethod: this.getSaveData,
      getInitRevisionDataMethod: this.getInitRevisionData,
      loadDetailDataMethod: this.loadData,
    };
  },
  mounted() {
    // this.pageStatus="Detail";
  },
  created() {
    this.pageStatus = "Add";
    let params = { ...this.$route.query };
    if (params.id) {
      this.pageStatus = "Edit";
    }
    // 默认加载数据
    this.loadData();
    // 监听类型选择事件
    EventBus.$on("productTypeChangeEvent", this.productTypeChangeEvent);
  },
  methods: {
    handleTabClick(tab) {
      if (tab.name === "tab1") {
        let params = { ...this.$route.query };
        if (params.id) {
          this.pageStatus = "Edit";
        } else {
          this.pageStatus = "Add";
        }
        let productType = this.loadSpecData.productType;
        this.dataTransfer(this.loadSpecData, productType);
      } else {
        this.pageStatus = "Detail";
        let tidx = tab.name.substring(3, tab.name.length);
        let data = this.tabList[tidx - 2];
        let productType = data.productType;
        this.dataTransfer(data, productType);
      }
    },
    loadData() {
      if (this.pageStatus != "Add") {
        let params = {
          id: this.$route.query.id,
        };
        this.$api.handleSpec.getNewSpecDetail(params).then((data) => {
          this.loadSpecData = data;
          let productType = data.productType;
          this.dataTransfer(data, productType);
        });
        this.$api.handleSpec.getNewSpecAuditedDetail(params).then((data) => {
          this.tabList = data;
        });
      } else {
        const initData = TypeConfig.typeData[0].data;
        this.dataTransfer(initData, "CSFMU-B");
      }
    },
    getControlData(item) {
      return this[item];
    },
    getInitRevisionData() {
      let revisionData = {
        revisionDate: "",
        requestedBy: "",
        revisedNotes: "",
        editedBy: "",
        reason: "",
        approvedBy: "",
        isComplete: false,
      };
      return revisionData;
    },
    getSaveData() {
      let model = {
        baseInfoData: this.baseInfoData,
        generalSpecificationData: this.generalSpecificationData,
        analysisData: this.analysisData,
        microbiologicalTestsData: this.microbiologicalTestsData,
        otherNotesData: this.otherNotesData,
        revisionData: this.revisionData,
        revNumber:this.loadSpecData.revNumber
      };
      console.info("model:", model);
      return model;
    },
    setDetailToDisable(obj, flag) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            this.setDetailToDisable(obj[key], flag); // 递归处理子对象
          } else if (key === "disabled") {
            obj[key] = flag; // 将 disabled 设置为 true
          }
        }
      }
    },
    dataTransfer(data, productType) {
      // console.warn(productType)
      productType = productType || "CSFMU-B";

      let jsonValue = JSON.parse(data.jsonValue);
      if (jsonValue.revision) {
        this.revisionData = jsonValue.revision;
        if (this.revisionData[this.revisionData.length - 1].isComplete) {
          this.revisionData.push(this.getInitRevisionData());
        }
      } else {
        this.revisionData = this.revisionData.push(this.getInitRevisionData());
      }

      // 记录版本信息
      this.setupVersionInfo(data);

      // 默认加载的模块
      this.itemList = TypeConfig.config.getFirstLayerAttribute(productType);
      // console.info(' this.itemList:', this.itemList)
      //加载数据,后续 api 读取
      // const loadData = TypeConfig.typeTestData[0].data;

      let loadData = "";
      if (this.pageStatus == "Edit" || this.pageStatus == "Detail") {
        loadData = JSON.parse(data.jsonValue);
      } else {
        //添加 使用模板数据
        loadData = TypeConfig.typeTestData[0].data;
        this.loading = false;
      }

      // console.info("loadData:----", loadData);
      //模板数据
      let templateData = TypeConfig.typeData.find(
        (item) => item.type === productType
      );
      // 如果是详情模式则所有控件设置disable为 true
      if (this.pageStatus == "Detail") {
        this.setDetailToDisable(templateData.data, true);
      } else {
        this.setDetailToDisable(templateData.data, false);
      }
      //转换数据
      const transformData = StructConvertUtils.transformMixedData(
        loadData,
        templateData.data
      );
      // console.info("transformData:----", transformData);

      Object.keys(TypeConfig.DataKeys).forEach((key) => {
        const dataKey = this.toCamelCase(TypeConfig.DataKeys[key]) + "Data";
        let rowNumber =
          key === "BASE_INFO" ||
          key === "GENERAL_SPECIFICATION" ||
          key === "OTHER_NOTES"
            ? 1
            : 2;

        // 默认空数据 获取数据
        // const list1 = TypeConfig.config.getTypeData(
        //   productType,
        //   TypeConfig.DataKeys[key]
        // );

        //处理数据
        const lowerKey = key.toLocaleLowerCase();
        // const list = TypeConfig.typeTestData[0].data[lowerKey];
        const list = transformData[lowerKey];

        // console.info("list1:",list1)
        // console.info("list:",list)
        this[dataKey] =
          key === "BASE_INFO" ||
          key === "GENERAL_SPECIFICATION" ||
          key === "OTHER_NOTES"
            ? {
                list,
                type: TypeConfig.DataKeys[key],
                row: rowNumber,
                model: dataKey,
              }
            : {
                ...this.getLeftRightList(list),
                type: TypeConfig.DataKeys[key],
                row: rowNumber,
                model: dataKey,
              };

        // 初始化 formData
        // console.info('dataKey',dataKey)
        // console.info('this[dataKey] ',this[dataKey] )
        // console.info('TypeConfig.DataKeys[key]',TypeConfig.DataKeys[key])
        this.$set(this.formData, TypeConfig.DataKeys[key], {});
      });
      this.defaultData = this.formData;
      this.loading = false;
    },
    setupVersionInfo(data) {
      if (this.pageStatus == "Edit") {
        // 记录版本信息
        this.versionInfo = {
          createName: data.createName ? data.createName : "",
          createTime: data.createTime ? data.createTime.substring(0, 10) : "",
          revNumber: data.revNumber ? data.revNumber : "",
          updateName: data.updateName ? data.updateName : "",
          updateTime: data.updateTime ? data.updateTime.substring(0, 10) : "",
          verifiedName: data.verifiedName ? data.verifiedName : "",
          verifiedTime: data.verifiedTime
            ? data.verifiedTime.substring(0, 10)
            : "",
        };
      }
    },
    productTypeChangeEvent(data) {
      this.selectType = data.type;
      if (this.pageStatus == "Add") {
        let templateData = TypeConfig.typeData.find(
          (item) => item.type === this.selectType
        );
        this.dataTransfer(templateData.data, this.selectType);
      } else {
        this.dataTransfer(this.loadSpecData, this.selectType);
      }
    },
    toCamelCase(str) {
      return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    },
    formatTitle(str) {
      return str
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/\b\w/g, (match) => match.toUpperCase());
    },
    mapAttributeModel(str) {
      return this[str.replace(/_([a-z])/g, (g) => g[1].toUpperCase()) + "Data"];
    },
    getLeftRightList(data) {
      const leftList = [];
      const rightList = [];
      if (!data) {
        return;
      }
      Object.keys(data).forEach((standardKey) => {
        const standard = data[standardKey];
        const capitalizedKey =
          standardKey.charAt(0).toUpperCase() + standardKey.slice(1);

        let st_tips_value = "";
        let st_disabled = false;
        let st_value = "";
        if(typeof(standard.test_method)!="undefined"){
          st_tips_value = standard.specification.tips_value;
          st_disabled = standard.specification.disabled;
          st_value = standard.specification.value;
        }
        leftList.push({
          type: "input",
          label: capitalizedKey,
          tips_value: st_tips_value,
          disabled: st_disabled,
          value: st_value,
        });

        let ts_tips_value = "";
        let ts_disabled = false;
        let ts_value = "";
        if(typeof(standard.test_method)!="undefined"){
          ts_tips_value = standard.test_method.tips_value;
          ts_disabled = standard.test_method.disabled;
          ts_value = standard.test_method.value;
        }
        rightList.push({
          type: "input",
          label: capitalizedKey,
          tips_value: ts_tips_value,
          disabled: ts_disabled,
          value: ts_value,
        });
      });
      return { leftList, rightList };
    },
  },
};
</script>

<style>
@import "./style/new-spec.css";
.tab {
  min-height: 500px;
}
</style>