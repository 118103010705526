export const ProductTypeData = [
  { "label": "CSFMU-S" },
  { "label": "CSFMU-B" },
  { "label": "F&V" },
  { "label": "FMU-D" },
  { "label": "HEXT" },
  { "label": "PCG" },
  { "label": "REXT" },
  { "label": "OEXT" },
  { "label": "PCG-CSFMU-S" },
  { "label": "PCG-CSFMU-B" },
  { "label": "SHXTO" },
  { "label": "EHXTO" },
  { "label": "Liquid EXT-S" },
  { "label": "Liquid EXT-B" },
  { "label": "SDNP" },
  { "label": "SDNP-L" }
];

export const PhysicalPropertiesData = [
  { "label": "Blend" },
  { "label": "Single" },
  { "label": "Granulate" },
  { "label": "Liquid" },
  { "label": "Oil" },
  { "label": "Essential oil" },
  { "label": "Glycerin" }
];

export const MeshSizeOptionsData = [
  { "label": "80-100 Mesh" },
  { "label": "20 Mesh" },
  { "label": "10 Mesh" },
  { "label": ">95% pass 80 Mesh" }
]

export const CarrierOptionsData = [
  { "label": "None" },
  { "label": "Maltodextrin" }
]

export const AppearanceOptionsData = [
  { "label": "Powder" },
  { "label": "Granular" }
]

export const GeneralSpecificationColorData = [
  { label: "Light brown" },
  { label: "Light yellow to yellow" },
  { label: "Light yellow to brownisn yellow" },
  { label: "Light reddish brown to dark brownish red" },
  { label: "Red" }
]

// 通用类型AnalysisData数据
export const AnalysisData = {
  leftTitle: "Specification",
  rightTitle: "Test Method",
  list: [{
    fields: "STANDARD 1：",
    modelFields: "standard1",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "STANDARD 2：",
    modelFields: "standard2",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "STANDARD 3：",
    modelFields: "standard3",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Mesh Size：",
    modelFields: "mesh_size",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: false,//select
    optionsData: MeshSizeOptionsData,
    rightIsInput: true,
    isCustom: false,
    isDefaultButton: true
  }, {
    fields: "Loss on Drying：",
    modelFields: "loss_on_drying",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Bulk Density：",
    modelFields: "bulk_density",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Identification：",
    modelFields: "identification",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Total Heavy Metals：",
    modelFields: "total_heavy_metals",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Arsenic：",
    modelFields: "arsenic",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Cadmium：",
    modelFields: "cadmium",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Lead：",
    modelFields: "lead",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Mercury：",
    modelFields: "mercury",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Color：",
    modelFields: "color",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: "false",
    isCustom: false
  }, {
    fields: "Odor：",
    modelFields: "odor",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Taste：",
    modelFields: "taste",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "analysis_custom_field_1",
    modelFields: "custom_field",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: "true"
  }]
};

// 通用MicrobiologicalTestsData数据
export const MicrobiologicalTestsData = {
  leftTitle: "Specification",
  rightTitle: "Test Method",
  list: [{
    fields: "Total Plate Count：",
    modelFields: "total_plate_count",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Yeast and Mold：",
    modelFields: "yeast_and_mold",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "E. Coli：",
    modelFields: "ecoli",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Salmonella：",
    modelFields: "salmonella",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Staph Aureus：",
    modelFields: "staph_aureus",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "Total Coliform：",
    modelFields: "total_coliform",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: false
  }, {
    fields: "analysis_custom_field_1",
    modelFields: "custom_field",
    specification: "",
    testMethod: "",
    prefix: "",
    suffix: "",
    leftIsInput: true,
    rightIsInput: true,
    isCustom: true
  }]
}

// SHXTO Limit Method 数据
export const LimitMethodData = {
  leftTitle: "Limit",
  rightTitle: "Method",
  list: [
    {
      fields: "Acid Value：",
      modelFields: "acid_value",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Peroxide Value：",
      modelFields: "peroxide_value",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Saponification Value：",
      modelFields: "saponification_value",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Unsaponifiable Matter：",
      modelFields: "unsaponifiable_matter",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Moisture & Volatiles：",
      modelFields: "moisture_volatiles",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Total Heavy Metals：",
      modelFields: "total_heavy_metals",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Arsenic：",
      modelFields: "arsenic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Cadmium：",
      modelFields: "cadmium",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Lead：",
      modelFields: "lead",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Mercury：",
      modelFields: "mercury",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "Pesticides：",
      modelFields: "pesticides",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "analysis_custom_field_1",
      modelFields: "custom_field",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: true
    }
  ]
}

export const FattyAcidCompositionData = {
  leftTitle: "Limit",
  rightTitle: "Method",
  list: [
    {
      fields: "C8:0 Caprylic acid:",
      modelFields: "caprylic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C10:0 Capric acid:",
      modelFields: "capric",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C12:0 Lauric acid:",
      modelFields: "lauric",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C14:0 Myristic acid:",
      modelFields: "myristic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C14:1 Myristoleic acid:",
      modelFields: "myristoleic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C16:0 Palmitic acid:",
      modelFields: "palmitic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C16:1 Palmitoleic acid:",
      modelFields: "palmitoleic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:0 Stearic acid:",
      modelFields: "stearic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:1 Oleic acid:",
      modelFields: "oleic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:2 Linoleic acid:",
      modelFields: "linoleic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:3 α-Linolenic acid:",
      modelFields: "a_linolenic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:3 γ-Linoleic acid:",
      modelFields: "γ-linoleic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C18:3 Punicic acid:",
      modelFields: "punicic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C 20:0 Arachidic acid:",
      modelFields: "arachidic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C20:4 Arachidonic acid:",
      modelFields: "arachidonic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C20:5 Eicosapentaenoic acid:",
      modelFields: "eicosapentaenoic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "C 22:6 Docosahexaenoic acid:",
      modelFields: "docosahexaenoic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "analysis_custom_field_1",
      modelFields: "custom_field",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: true
    },
    {
      fields: "Others:",
      modelFields: "others",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }
  ]
}

export const EhxtoAnalysisData = {
  leftTitle: "Limit",
  rightTitle: "Test Method",
  list: [
    {
      fields: "Moisture:",
      modelFields: "moisture",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Specific Gravity:",
      modelFields: "specific_gravity",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Refractive Index:",
      modelFields: "refractive_index",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Optical Rotation:",
      modelFields: "optical_rotation",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Total Heavy Metals:",
      modelFields: "total_heavy_metals",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Arsenic (As):",
      modelFields: "arsenic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Cadmium (Cd) :",
      modelFields: "cadmium",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Lead (Pd):",
      modelFields: "lead",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Mercury (Hg):",
      modelFields: "mercury",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }, {
      fields: "analysis_custom_field_1",
      modelFields: "custom_field",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: true
    }
  ]
}

export const LiquidAnalysisData = {
  leftTitle: "Range",
  rightTitle: "Test Method",
  list: [
    {
      fields: "Bioactives level:",
      modelFields: "bioactives_level",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "analysis_custom_field_1",
      modelFields: "custom_field",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: true
    },
    {
      fields: "pH:",
      modelFields: "ph",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Heavy Metal:",
      modelFields: "heavy_metal",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Arsenic:",
      modelFields: "arsenic",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Cadmium:",
      modelFields: "cadmium",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Lead:",
      modelFields: "lead",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Mercury:",
      modelFields: "mercury",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Pesticide:",
      modelFields: "pesticide",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Total Plate Count:",
      modelFields: "total_plate_count",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Yeast & Mold:",
      modelFields: "yeast_and_mold",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "E. Coli:",
      modelFields: "e-coli",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Salmonella:",
      modelFields: "salmonella",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Staph Aureus:",
      modelFields: "staph_aureus",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    },
    {
      fields: "Total Coliform:",
      modelFields: "total_coliform",
      specification: "",
      testMethod: "",
      prefix: "",
      suffix: "",
      leftIsInput: true,
      rightIsInput: true,
      isCustom: false
    }
  ]
}